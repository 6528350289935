<template>
  <v-container class="mt-5 ml-2">
    <v-row>
      <v-col cols="12" lg="8">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            Familias
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              small
              @click="dialog = true"
            >
              Agregar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="6">
                <v-card-text class="py-0">
                  <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar"
                    id="id"
                    filled
                    dense
                    v-model="search"
                  ></v-text-field>
                </v-card-text>
              </v-col>
            </v-row>

            <v-data-table
              :headers="headers"
              :items="familias"
              sort-by="estatus"
              class="elevation-0"
              :search="search"
              dense
              :loading="cargar"
            >
              <template v-slot:top>
               
              </template>
              <template v-slot:item.editar="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                  color="blue"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                  dense
                  small
                >
                  Actualizar
                </v-btn>
              </template>

              <template v-slot:item.estatus="{ item }">
                <v-chip v-if="item.estatus == 1" color="green" dark small>Activo</v-chip>
                <v-chip v-else color="red" dark small>Inactivo</v-chip>
              </template>

              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                  dense
                  small
                >
                  Actualizar
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text class="pb-0">
            <v-text-field
              v-model="editedItem.familia"
              label="Familia"
              outlined
              dense
              :rules="[v => !!v || 'Campo requerido']"
            ></v-text-field>

            <v-checkbox 
              label="Activo" 
              v-model="editedItem.estatus" 
            >
            </v-checkbox>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="close"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="save"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import validarErrores        from '@/mixins/validarErrores'

  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

    	cargar: false,
      valid: true,
      search:'',
      text:'',
      color:'',
      snackbar: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Id', align: 'left',sortable: false, value: 'idfamilias'},
        { text: 'Nombre', align: 'left',sortable: false, value: 'familia'},
        { text: 'Estatus', align: 'left',sortable: false, value: 'estatus'},
        { text: 'Editar', align: 'left',sortable: false, value: 'editar'},
      ],

      familias: [],
      editedIndex: -1,
      editedItem: {
        idfamilias:0,
        familia: '',
        estatus: 0,
      },
      defaultItem: {
        idfamilias:0,
        familia: '',
        estatus: 0,
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva Familia' : 'Editar Familia'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {

      initialize () {
      	this.cargar = true
        this.familias = []
        this.$http.get('familias.all').then(response =>{
          this.cargar   = false
          this.familias = response.data

          this.familias.forEach(element=>{
            element.estatus == 1 ? true:false
          })
      		this.cargar = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.familias.indexOf(item)
        this.editedItem = Object.assign({}, item)
        console.log(this.editedItem.estatus)
        this.editedItem.estatus = this.editedItem.estatus == 0 ? false : true
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.familias.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.familias.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if(this.$refs.form.validate()){
          this.editedItem.estatus = this.editedItem.estatus == 0 ? false : true

          if (this.editedIndex > -1) {
            this.$http.put('familias.update/'+ this.editedItem.idfamilias, this.editedItem).then(response=>{
              this.validarSuccess('Familia actualizada')
              this.initialize()
            }).catch( error =>{
              this.validarError( error.response.data.message )
            }).finally( () => { this.cargar = false })
          } else {
            this.$http.post('familias.add', this.editedItem).then(response=>{
              this.validarSuccess('Familia agregada')
              this.initialize()
            }).catch( error =>{
              this.validarError( error.response.data.message )
            }).finally( () => { this.cargar = false })
          }
          this.close()
        }
    },
  },
}
</script>